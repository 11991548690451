import { Flex, Slider } from "@mantine/core";
import { DataTableColumn } from "mantine-datatable";
import { useMemo } from "react";

import { TableData, TableRow } from "../../../../components/Table/types.ts";
import { CreditLimitFunnel } from "../../../../types/riskFunnelDashboard.ts";
import { toCurrencyPrice, toPercent } from "../../../../utils/number.ts";
// import { useStyles } from "../CreditLimitFunnel.styles.ts";
import RatePin from "../RatePin/RatePin.tsx";
import { LimitTypeNameOptions } from "./constants.ts";

interface Props {
  data: CreditLimitFunnel[] | null | undefined;
}

export const useCreditLimitFunnelTableData = ({ data }: Props): TableData => {
  // const { classes } = useStyles();

  const columns: DataTableColumn<TableRow>[] = [
    {
      accessor: "limitTypeName",
    },
    {
      accessor: "limitRequestedNumber",
      title: "Number of Limit requested",
      width: 400,
    },
    {
      accessor: "conversionRate",
      title: "Conversion rate",
    },
    {
      accessor: "accountNumber",
      title: "Number of Account",
    },
    {
      accessor: "totalNominal",
      title: "Total nominal",
    },
    // {
    //   accessor: "sla",
    //   title: "Average SLA (mins)",
    // },
  ];

  const rows = useMemo(() => {
    return data?.map((item) => {
      return {
        limitTypeName: LimitTypeNameOptions[item.limitTypeName],
        limitRequestedNumber: (
          <Flex w="100%" align="center" justify="space-between" gap={4}>
            {toPercent(item.limitRequestedNumber)}
            <Slider
              w="100%"
              maw="320px"
              value={item.limitRequestedNumber * 100}
              disabled
            />
          </Flex>
        ),
        conversionRate: <RatePin conversionRate={item.conversionRate} />,
        accountNumber: item.accountNumber,
        totalNominal: toCurrencyPrice(item.totalNominal),
        // sla: (
        //   <Flex justify="center" align="center" className={classes.slaWrapper}>
        //     {item.sla}
        //   </Flex>
        // ),
      };
    });
  }, [data]);

  return [columns, rows];
};

import { useMemo } from "react";

import { MultiFieldCardItem } from "../../../../../components/Cards/MultiFieldCard/MultiFieldCard.tsx";
import { DatabaseMetadataPopover } from "../../../../../components/DatabaseMetadataPopover/DatabaseMetadataPopover.tsx";
import { ClikResult } from "../../../../../types/clikResult.ts";
import { toLocalDate } from "../../../../../utils/date.ts";
import { toCurrencyPrice } from "../../../../../utils/number.ts";
import { toHumanReadable } from "../../../../../utils/string.ts";

interface Props {
  clikResult: ClikResult;
}

export interface CRCompanyInfo {
  subject: MultiFieldCardItem[];
  establishmentData: MultiFieldCardItem[];
  applicationSummary: MultiFieldCardItem[];
}

export const useCompanyResultInfoCards = ({
  clikResult,
}: Props): CRCompanyInfo => {
  const subject = useMemo(() => {
    return [
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikResult",
              fieldName: "cbScore",
            }}
            position="right"
          >
            CB Subject Code
          </DatabaseMetadataPopover>
        ),
        content: clikResult.cbScore,
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikCompanyMatchedSubject",
              fieldName: "providerSubjectNumber",
            }}
            position="right"
          >
            Provider Subject No
          </DatabaseMetadataPopover>
        ),
        content:
          clikResult.providerSubjectNumber ??
          clikResult.companySubject?.providerSubjectNumber,
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikCompanyMatchedSubject",
              fieldName: "lastUpdatedDate",
            }}
            position="right"
          >
            Last Update Date
          </DatabaseMetadataPopover>
        ),
        content: toLocalDate(clikResult.companySubject?.lastUpdatedDate),
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikCompanyMatchedSubject",
              fieldName: "goPublic",
            }}
            position="right"
          >
            Go Public
          </DatabaseMetadataPopover>
        ),
        content:
          clikResult?.companySubject?.goPublic &&
          toHumanReadable(clikResult.companySubject.goPublic),
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikCompanyMatchedSubject",
              fieldName: "tradeName",
            }}
            position="right"
          >
            Trade Name
          </DatabaseMetadataPopover>
        ),
        content: clikResult.companySubject?.tradeName,
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikCompanyMatchedSubject",
              fieldName: "companyCategory",
            }}
            position="right"
          >
            Company Category
          </DatabaseMetadataPopover>
        ),
        content: clikResult.companySubject?.companyCategory,
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikCompanyMatchedSubject",
              fieldName: "legalForm",
            }}
            position="right"
          >
            Legal Form
          </DatabaseMetadataPopover>
        ),
        content: clikResult?.companySubject?.legalForm,
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikCompanyMatchedSubject",
              fieldName: "economicSector",
            }}
            position="right"
          >
            Economic Sector
          </DatabaseMetadataPopover>
        ),
        content: clikResult?.companySubject?.economicSector,
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikCompanyMatchedSubject",
              fieldName: "rating",
            }}
            position="right"
          >
            Rating
          </DatabaseMetadataPopover>
        ),
        content: clikResult?.companySubject?.rating,
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikCompanyMatchedSubject",
              fieldName: "ratingInstitution",
            }}
            position="right"
          >
            Rating Institution
          </DatabaseMetadataPopover>
        ),
        content: clikResult?.companySubject?.ratingInstitution,
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikCompanyMatchedSubject",
              fieldName: "ratingDate",
            }}
            position="right"
          >
            Rating Date
          </DatabaseMetadataPopover>
        ),
        content:
          clikResult?.companySubject?.ratingDate &&
          toLocalDate(clikResult.companySubject.ratingDate),
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikCompanyMatchedSubject",
              fieldName: "shareholdersCount",
            }}
            position="right"
          >
            Shareholders Counter
          </DatabaseMetadataPopover>
        ),
        content: clikResult?.companySubject?.shareholdersCount,
      },
    ];
  }, [clikResult]);

  const establishmentData = useMemo(() => {
    return [
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikCompanyEstablishment",
              fieldName: "placeOfEstablishment",
            }}
            position="right"
          >
            Place of Establishment
          </DatabaseMetadataPopover>
        ),
        content:
          clikResult?.companySubject?.establishment?.placeOfEstablishment,
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikCompanyEstablishment",
              fieldName: "deedNumberOfEstablishment",
            }}
            position="right"
          >
            Deed Number of Establishment
          </DatabaseMetadataPopover>
        ),
        content:
          clikResult?.companySubject?.establishment?.deedNumberOfEstablishment,
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikCompanyEstablishment",
              fieldName: "deedDateOfEstablishment",
            }}
            position="right"
          >
            Deed Date of Establishment
          </DatabaseMetadataPopover>
        ),
        content:
          clikResult?.companySubject?.establishment?.deedDateOfEstablishment,
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikCompanyEstablishment",
              fieldName: "recentlyAmendmentDeedNumber",
            }}
            position="right"
          >
            Recently Amendment Deed Number
          </DatabaseMetadataPopover>
        ),
        content:
          clikResult?.companySubject?.establishment
            ?.recentlyAmendmentDeedNumber,
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikCompanyEstablishment",
              fieldName: "dateofFinalAmendmentDeedNumber",
            }}
            position="right"
          >
            Date of Final Amendment Deed
          </DatabaseMetadataPopover>
        ),
        content:
          clikResult?.companySubject?.establishment
            ?.dateofFinalAmendmentDeedNumber &&
          toLocalDate(
            clikResult.companySubject.establishment
              .dateofFinalAmendmentDeedNumber
          ),
      },
    ];
  }, [clikResult]);

  const applicationSummary = useMemo(() => {
    return [
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikApplicationSummary",
              fieldName: "cbContractCode",
            }}
            position="right"
          >
            CB Contract Code
          </DatabaseMetadataPopover>
        ),
        content: clikResult?.applicationSummary?.cbContractCode,
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikApplicationSummary",
              fieldName: "contractType",
            }}
            position="right"
          >
            Contract Type
          </DatabaseMetadataPopover>
        ),
        content:
          clikResult?.applicationSummary?.contractType &&
          toHumanReadable(clikResult.applicationSummary.contractType),
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikApplicationSummary",
              fieldName: "contractPhase",
            }}
            position="right"
          >
            Contract Phase
          </DatabaseMetadataPopover>
        ),
        content:
          clikResult?.applicationSummary?.contractPhase &&
          toHumanReadable(clikResult.applicationSummary.contractPhase),
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikApplicationSummary",
              fieldName: "requestDate",
            }}
            position="right"
          >
            Request Date
          </DatabaseMetadataPopover>
        ),
        content:
          clikResult?.applicationSummary?.requestDate &&
          toLocalDate(clikResult.applicationSummary.requestDate),
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikApplicationSummary",
              fieldName: "amount",
            }}
            position="right"
          >
            Application Amount
          </DatabaseMetadataPopover>
        ),
        content:
          clikResult?.applicationSummary?.amount &&
          toCurrencyPrice(clikResult.applicationSummary.amount),
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikApplicationSummary",
              fieldName: "dueDate",
            }}
            position="right"
          >
            Due Date
          </DatabaseMetadataPopover>
        ),
        content:
          clikResult?.applicationSummary?.dueDate &&
          toLocalDate(clikResult.applicationSummary.dueDate),
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikApplicationSummary",
              fieldName: "role",
            }}
            position="right"
          >
            Role
          </DatabaseMetadataPopover>
        ),
        content: clikResult?.applicationSummary?.role,
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikApplicationSummary",
              fieldName: "companyRole",
            }}
            position="right"
          >
            Company Role
          </DatabaseMetadataPopover>
        ),
        content: clikResult?.applicationSummary?.companyRole,
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikApplicationSummary",
              fieldName: "accountNumber",
            }}
            position="right"
          >
            Account Number
          </DatabaseMetadataPopover>
        ),
        content: clikResult?.applicationSummary?.accountNumber,
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikApplicationSummary",
              fieldName: "originalAgreementNumber",
            }}
            position="right"
          >
            Original Agreement Number
          </DatabaseMetadataPopover>
        ),
        content: clikResult?.applicationSummary?.originalAgreementNumber,
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikApplicationSummary",
              fieldName: "originalAgreementDate",
            }}
            position="right"
          >
            Original Agreement Date
          </DatabaseMetadataPopover>
        ),
        content:
          clikResult?.applicationSummary?.originalAgreementDate &&
          toLocalDate(clikResult.applicationSummary.originalAgreementDate),
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikApplicationSummary",
              fieldName: "currency",
            }}
            position="right"
          >
            Currency
          </DatabaseMetadataPopover>
        ),
        content: clikResult?.applicationSummary?.currency,
      },
    ];
  }, [clikResult]);

  return { subject, establishmentData, applicationSummary };
};

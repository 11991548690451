import {
  Button,
  Checkbox,
  Flex,
  Input,
  Modal,
  NumberInput,
  ScrollArea,
  Select,
  SimpleGrid,
  Textarea,
} from "@mantine/core";
import { isNotEmpty, useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { useCallback } from "react";

import {
  CreateLateFeeInput,
  FileResult,
  PaymentMethod,
  PaymentType,
} from "../../graphql/generated.ts";
import {
  Invoice,
  paymentMethodOptions,
  paymentTypeOptions,
} from "../../types/invoice/invoice.ts";
import { parseStringToNumber, priceFormatter } from "../../utils/number.ts";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal.tsx";
import Dropzone from "../Dropzone/Dropzone.tsx";

interface Props {
  invoices: Invoice[] | null | undefined;
  opened: boolean;
  onClose: () => void;
  onSubmit: (value: CreateLateFeeInput) => void;
}

interface FormValues {
  invoiceId: string | null;
  paymentType: PaymentType | null;
  paymentMethod: PaymentMethod | null;
  previousAmount: number;
  amount: number;
  changeAmount: number;
  removeAllLateFee: boolean;
  documents: FileResult[];
  notes: string;
}

const LateFeeModal = ({ invoices, opened, onClose, onSubmit }: Props) => {
  const [
    confirmationModalOpened,
    { open: openConfirmationModal, close: closeConfirmationModal },
  ] = useDisclosure(false);

  const form = useForm<FormValues>({
    validate: {
      invoiceId: isNotEmpty("Invoice No is required"),
      paymentType: isNotEmpty("Payment Type is required"),
      paymentMethod: isNotEmpty("Payment Method is required"),
      changeAmount: isNotEmpty("Change Amount is required"),
      documents: isNotEmpty("Documents are required"),
      notes: isNotEmpty("Notes are required"),
    },
    validateInputOnBlur: true,
  });

  const invoicesOptions = (invoices ?? []).map((invoice) => ({
    value: String(invoice.id),
    label: invoice.invoiceNumber,
  }));

  const handleClose = useCallback(() => {
    form.reset();
    onClose();
  }, [form, onClose]);

  const handleSubmit = useCallback(() => {
    if (!form.isValid()) {
      return;
    }
    onSubmit({
      invoiceId: Number(form.values.invoiceId),
      amount: form.values.amount,
      previousAmount: form.values.previousAmount,
      documents: form.values.documents.map((doc) => doc.filePath),
      removeLateCharge: form.values.removeAllLateFee ?? false,
      notes: form.values.notes,
    } as CreateLateFeeInput);
    handleClose();
  }, [form, handleClose, onSubmit]);

  const handleChangeInvoice = useCallback(
    (value: string | null) => {
      const invoiceId = Number(value);
      if (isNaN(invoiceId)) {
        return;
      }
      const invoice = (invoices ?? []).find(
        (invoice) => invoice.id === invoiceId
      );
      if (!invoice) {
        return;
      }
      form.setValues({
        invoiceId: value,
        paymentType: invoice.paymentType,
        paymentMethod: invoice.paymentMethod,
        previousAmount: Number(invoice.lateCharge),
      });
    },
    [form, invoices]
  );

  const handleChangeRemoveAllLateFee = useCallback(
    (value: boolean) => {
      if (value) {
        form.setValues((values) => ({
          removeAllLateFee: true,
          amount: 0,
          changeAmount: values.previousAmount,
        }));
      } else {
        form.setValues((values) => ({
          removeAllLateFee: false,
          changeAmount: 0,
          amount: values.previousAmount,
        }));
      }
    },
    [form]
  );

  const handleChangeChangeAmount = useCallback(
    (value: number | string) => {
      const numberValue = Number(value);
      form.setValues((values) => ({
        changeAmount: numberValue,
        amount: (values.previousAmount ?? 0) - numberValue,
      }));
    },
    [form]
  );

  const handleUploadFiles = (files: FileResult[]) => {
    form.setValues((values) => ({
      documents: [...(values.documents ?? []), ...files],
    }));
  };

  const handleRemoveFile = (filePath: string) => {
    form.setValues({
      documents: form.values.documents.filter((f) => f.filePath !== filePath),
    });
  };

  return (
    <>
      <Modal
        opened={opened}
        title="Update Late Fee"
        closeButtonProps={{
          size: 24,
          iconSize: 24,
        }}
        size="xl"
        scrollAreaComponent={ScrollArea.Autosize}
        onClose={handleClose}
      >
        <Flex direction="column" gap={20}>
          <SimpleGrid cols={2} spacing={20} verticalSpacing={8}>
            <Flex gap={12} direction="column">
              <Select
                withinPortal
                required
                data={invoicesOptions}
                label="Invoice No"
                placeholder="Select Invoice No"
                size="m"
                {...form.getInputProps("invoiceId")}
                onChange={(value) => handleChangeInvoice(value)}
              />
              <Select
                withinPortal
                required
                disabled
                data={paymentTypeOptions}
                label="Payment Type"
                placeholder="Payment Type"
                size="m"
                {...form.getInputProps("paymentType")}
              />
              <Select
                withinPortal
                required
                disabled
                data={paymentMethodOptions}
                label="Payment Method"
                placeholder="Payment Method"
                size="m"
                {...form.getInputProps("paymentMethod")}
              />
              <NumberInput
                required
                disabled
                precision={2}
                parser={parseStringToNumber}
                formatter={priceFormatter}
                hideControls
                label="Current Late Fee"
                placeholder="Current Late Fee"
                size="m"
                {...form.getInputProps("previousAmount")}
              />
            </Flex>
            <Flex gap={12} direction="column">
              <NumberInput
                required
                disabled={
                  !form.values.invoiceId || form.values.removeAllLateFee
                }
                precision={2}
                parser={parseStringToNumber}
                formatter={priceFormatter}
                hideControls
                label="Change Amount"
                placeholder="Enter Change Amount"
                size="m"
                {...form.getInputProps("changeAmount")}
                onChange={(value) => handleChangeChangeAmount(value)}
              />
              <Checkbox
                label="Remove all Late Fee"
                size="md"
                variant="golden"
                disabled={!form.values.invoiceId}
                {...form.getInputProps("removeAllLateFee")}
                onChange={(event) =>
                  handleChangeRemoveAllLateFee(event.target.checked)
                }
              />
              <NumberInput
                required
                disabled
                precision={2}
                parser={parseStringToNumber}
                formatter={priceFormatter}
                hideControls
                label="New Late Fee"
                placeholder="New Late Fee"
                size="m"
                {...form.getInputProps("amount")}
              />
              <Input.Wrapper label="Documents" size="m" w="100%" required>
                <Dropzone
                  maxWidth="100%"
                  value={form.values.documents}
                  onUploadSuccess={(value) => handleUploadFiles(value)}
                  onRemove={(value) => handleRemoveFile(value)}
                />
              </Input.Wrapper>
              <Textarea
                label="Notes"
                placeholder="Enter Notes"
                autosize
                required
                size="m"
                minRows={3}
                {...form.getInputProps("notes")}
              />
            </Flex>
          </SimpleGrid>
          <Flex gap={20} justify="end">
            <Button
              variant="outlineBlue"
              uppercase
              type="reset"
              onClick={onClose}
            >
              cancel
            </Button>
            <Button
              variant="filledBlue"
              uppercase
              type="submit"
              disabled={!form.isValid()}
              onClick={openConfirmationModal}
            >
              save
            </Button>
          </Flex>
        </Flex>
      </Modal>
      {confirmationModalOpened && (
        <ConfirmationModal
          opened={true}
          message={
            "Are you sure you want to update the late fee? This process is irreversible. " +
            "Customers' late fee will be changed permanently based on the changes."
          }
          onConfirm={handleSubmit}
          onClose={closeConfirmationModal}
        />
      )}
    </>
  );
};

export default LateFeeModal;

import {
  Button,
  Flex,
  MediaQuery,
  MultiSelect,
  SimpleGrid,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { useEffect, useMemo } from "react";

import FilterMobile from "../../../components/FilterMobile/FilterMobile.tsx";
import SvgFilter from "../../../components/Icons/Filter.tsx";
import SvgUnfold from "../../../components/Icons/Unfold.tsx";
import {
  paymentStatusOptions,
  paymentTypeOptions,
} from "../../../types/invoice/invoice.ts";
import { UserOption } from "../../../types/user.ts";
import { getFiltersCount } from "../../../utils/filter.ts";
import { getUserOptions } from "../../../utils/user.ts";
import { useStyles } from "./InvoicesFilterToolbar.styles.ts";
import { FiltersValue } from "./types.ts";

interface InvoicesToolbarProps {
  collectors?: UserOption[] | null | undefined;
  onValueChange: (value: Partial<FiltersValue>) => void;
}

const InvoicesFilterToolbar = ({
  collectors,
  onValueChange,
}: InvoicesToolbarProps) => {
  const { classes } = useStyles();

  const form = useForm<FiltersValue>({
    initialValues: {
      invoiceDueDate: undefined,
      paymentType: undefined,
      paymentStatus: undefined,
      collectorAssignedId: undefined,
    },
    validateInputOnBlur: true,
  });

  const isCollectorFilterShow = !!collectors;

  const isPaymentTypeFilterShow = !collectors;

  const isFilterApplied = useMemo(
    () => !!getFiltersCount(form.values),
    [form.values]
  );

  const [
    filterModalOpened,
    { open: filterModalOpen, close: filterModalClose },
  ] = useDisclosure(false);

  const handleApplyFilter = () => {
    onValueChange(form.values);
    filterModalClose();
  };

  const handleCleanFilters = () => {
    form.reset();
    handleApplyFilter();
  };

  useEffect(() => {
    !filterModalOpened && onValueChange(form.values);
  }, [form.values, filterModalOpened, onValueChange]);

  return (
    <>
      <MediaQuery smallerThan="sm" styles={{ display: "none" }}>
        <SimpleGrid cols={3} spacing={20} w="100%">
          <DatePickerInput
            size="l"
            label="Invoice Due Date"
            placeholder="Select Invoice Due Date"
            rightSection={!form.values.invoiceDueDate && <SvgUnfold />}
            clearable
            {...form.getInputProps("invoiceDueDate")}
          />
          {isPaymentTypeFilterShow && (
            <MultiSelect
              size="l"
              data={paymentTypeOptions}
              label="Payment Type"
              placeholder="Select Payment Type"
              searchable
              rightSection={<SvgUnfold />}
              nothingFound="No results found"
              {...form.getInputProps("paymentType")}
            />
          )}
          <MultiSelect
            size="l"
            data={paymentStatusOptions}
            label="Payment Status"
            placeholder="Select Payment Status"
            searchable
            rightSection={<SvgUnfold />}
            nothingFound="No results found"
            {...form.getInputProps("paymentStatus")}
          />
          {isCollectorFilterShow && (
            <MultiSelect
              size="l"
              data={getUserOptions(collectors)}
              label="Collector Assigned"
              placeholder="Select Collector Assigned"
              searchable
              rightSection={<SvgUnfold />}
              nothingFound="No results found"
              {...form.getInputProps("collectorAssignedId")}
            />
          )}
        </SimpleGrid>
      </MediaQuery>
      <MediaQuery largerThan="sm" styles={{ display: "none" }}>
        <Flex direction="column" gap={16} w="100%">
          <Button
            variant="filledGolden"
            className={classes.filterButton}
            leftIcon={<SvgFilter />}
            uppercase
            fullWidth
            onClick={filterModalOpen}
          >
            Filter
            {isFilterApplied && ` (${getFiltersCount(form.values)})`}
          </Button>
        </Flex>
      </MediaQuery>
      <FilterMobile
        isActive={isFilterApplied}
        opened={filterModalOpened}
        onClose={filterModalClose}
        onClean={handleCleanFilters}
        onApply={handleApplyFilter}
      >
        <Flex direction="column" gap={12}>
          <DatePickerInput
            size="m"
            label="Invoice Due Date"
            placeholder="Select Invoice Due Date"
            rightSection={!form.values.invoiceDueDate && <SvgUnfold />}
            clearable
            {...form.getInputProps("invoiceDueDate")}
          />
          {isPaymentTypeFilterShow && (
            <MultiSelect
              size="m"
              data={paymentTypeOptions}
              label="Payment Type"
              placeholder="Select Payment Type"
              searchable
              rightSection={<SvgUnfold />}
              nothingFound="No results found"
              {...form.getInputProps("paymentType")}
            />
          )}
          <MultiSelect
            size="m"
            data={paymentStatusOptions}
            label="Payment Status"
            placeholder="Select Payment Status"
            searchable
            rightSection={<SvgUnfold />}
            nothingFound="No results found"
            {...form.getInputProps("paymentStatus")}
          />
          {isCollectorFilterShow && (
            <MultiSelect
              size="m"
              data={getUserOptions(collectors)}
              label="Collector Assigned"
              placeholder="Select Collector Assigned"
              searchable
              rightSection={<SvgUnfold />}
              nothingFound="No results found"
              {...form.getInputProps("collectorAssignedId")}
            />
          )}
        </Flex>
      </FilterMobile>
    </>
  );
};

export default InvoicesFilterToolbar;

import {
  Button,
  Flex,
  Input,
  Modal,
  ScrollArea,
  Select,
  Textarea,
  TextInput,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { isNotEmpty, useForm } from "@mantine/form";
import { ReactNode, useCallback } from "react";

import Dropzone from "../../../components/Dropzone/Dropzone.tsx";
import SvgCalendar from "../../../components/Icons/Calendar.tsx";
import { yesNoOptions } from "../../../constants.ts";
import {
  FileResult,
  LimitRequestStatus,
  UpdateOfferLetterInput,
} from "../../../graphql/generated.ts";
import { LimitRequestDetail } from "../../../types/limitRequest.ts";
import { OfferLetter } from "../../../types/offerLetter.ts";
import { booleanToString, stringToBoolean } from "../../../utils/boolean.ts";
import { offerLetterStatusOptions } from "./utils.ts";

interface Props {
  value: OfferLetter | null | undefined;
  limitRequest:
    | Pick<LimitRequestDetail, "id" | "requestStatus" | "offerLetter">
    | null
    | undefined;
  opened: boolean;
  onClose: () => void;
  onSubmit: (value: UpdateOfferLetterInput) => void;
}

const OfferingLetterModal = ({
  value,
  limitRequest,
  opened,
  onClose,
  onSubmit,
}: Props) => {
  const formState =
    limitRequest?.requestStatus === LimitRequestStatus.Approved
      ? {
          initialValues: {
            olSigned: value?.olSigned,
            pksSigned: value?.pksSigned,
            pgSigned: value?.pgSigned,
          },
          validate: {},
        }
      : {
          initialValues: {
            olNo: value?.olNo,
            olSignedDate: value?.olSignedDate
              ? new Date(value.olSignedDate)
              : undefined,
            olSigned: value?.olSigned,
            pksSigned: value?.pksSigned,
            pgSigned: value?.pgSigned,
            olExpiredDate: value?.olExpiredDate
              ? new Date(value.olExpiredDate)
              : undefined,
            olStatus: value?.olStatus,
            giroRegis: booleanToString(value?.giroRegis, "formValue"),
            notes: value?.notes,
          },
          validate: {
            olNo: isNotEmpty("Ol No is required"),
            olSignedDate: isNotEmpty("Ol Signed Date is required"),
            olExpiredDate: isNotEmpty("Ol Expired Date is required"),
            olStatus: isNotEmpty("Ol Status is required"),
          },
        };
  const form = useForm({
    initialValues: formState.initialValues,
    validate: formState.validate,
    validateInputOnBlur: true,
  });

  const handleClose = useCallback(() => {
    form.reset();
    onClose();
  }, [form, onClose]);

  const handleSubmit = useCallback(() => {
    if (!form.isValid()) {
      return;
    }
    onSubmit({
      ...form.values,
      olSigned: form.values.olSigned?.filePath,
      pksSigned: (form.values.pksSigned ?? []).map((file) => file.filePath),
      pgSigned: (form.values.pgSigned ?? []).map((file) => file.filePath),
      giroRegis: stringToBoolean(form.values.giroRegis, true),
    } as UpdateOfferLetterInput);
    handleClose();
  }, [form, handleClose, onSubmit]);

  const handleFileUploaded = (
    name: "olSigned" | "pgSigned" | "pksSigned",
    files: FileResult[]
  ) => {
    if (name === "olSigned") {
      form.setValues({ olSigned: files[0] });
    }
    if (name === "pksSigned") {
      form.setValues({ pksSigned: files });
    }
    if (name === "pgSigned") {
      form.setValues({ pgSigned: files });
    }
  };

  const handleRemoveFile = (
    name: "olSigned" | "pgSigned" | "pksSigned",
    value?: string
  ) => {
    if (!value && name !== "olSigned") {
      return;
    }
    if (name === "olSigned") {
      form.setValues({ olSigned: undefined });
    }
    if (name === "pksSigned") {
      form.setValues({
        pksSigned: form.values.pksSigned?.filter(
          (file) => file.filePath !== value
        ),
      });
    }
    if (name === "pgSigned") {
      form.setValues({
        pgSigned: form.values.pgSigned?.filter(
          (file) => file.filePath !== value
        ),
      });
    }
  };

  const fileInputs = (
    <>
      <Input.Wrapper label="Signed OL File" size="m" w="100%">
        <Dropzone
          maxFiles={1}
          maxWidth="unset"
          value={form.values.olSigned ? [form.values.olSigned] : null}
          onUploadSuccess={(value) => handleFileUploaded("olSigned", value)}
          onRemove={() => handleRemoveFile("olSigned")}
        />
      </Input.Wrapper>
      <Input.Wrapper label="Signed PKS Files" size="m" w="100%">
        <Dropzone
          maxWidth="unset"
          value={form.values.pksSigned}
          multiple={true}
          onUploadSuccess={(value) => handleFileUploaded("pksSigned", value)}
          onRemove={(value) => handleRemoveFile("pksSigned", value)}
        />
      </Input.Wrapper>
      <Input.Wrapper label="Signed PG Files" size="m" w="100%">
        <Dropzone
          maxWidth="unset"
          value={form.values.pgSigned}
          multiple={true}
          onUploadSuccess={(value) => handleFileUploaded("pgSigned", value)}
          onRemove={(value) => handleRemoveFile("pgSigned", value)}
        />
      </Input.Wrapper>
    </>
  );

  const renderModal = useCallback(
    (children: ReactNode) => (
      <Modal
        opened={opened}
        title={value ? "Edit Offering Letter" : "Add Offering Letter"}
        closeButtonProps={{
          size: 24,
          iconSize: 24,
        }}
        size="lg"
        scrollAreaComponent={ScrollArea.Autosize}
        onClose={handleClose}
      >
        <Flex direction="column" gap={20}>
          <Flex gap={12} direction="column">
            {children}
          </Flex>
          <Flex gap={20} justify="end">
            <Button
              variant="outlineBlue"
              uppercase
              type="reset"
              onClick={onClose}
            >
              cancel
            </Button>
            <Button
              variant="filledBlue"
              uppercase
              type="submit"
              disabled={!form.isValid() || !form.isDirty()}
              onClick={handleSubmit}
            >
              {value ? "edit" : "add"}
            </Button>
          </Flex>
        </Flex>
      </Modal>
    ),
    [form, handleClose, handleSubmit, onClose, opened, value]
  );

  if (limitRequest?.requestStatus === LimitRequestStatus.Approved) {
    return renderModal(fileInputs);
  }

  return renderModal(
    <>
      <TextInput
        required
        label="Ol No"
        placeholder="Enter Ol No"
        size="m"
        {...form.getInputProps("olNo")}
      />
      <DatePickerInput
        label="OL Signing Date"
        placeholder="Select OL Signing Date"
        icon={<SvgCalendar />}
        firstDayOfWeek={0}
        size="m"
        valueFormat="YYYY MMM DD"
        popoverProps={{ zIndex: 1000, withinPortal: true }}
        maxDate={new Date()}
        clearable
        required
        {...form.getInputProps("olSignedDate")}
      />
      {fileInputs}
      <DatePickerInput
        label="OL Expired Date"
        placeholder="Select OL Expired Date"
        icon={<SvgCalendar />}
        firstDayOfWeek={0}
        popoverProps={{ zIndex: 1000, withinPortal: true }}
        size="m"
        valueFormat="YYYY MMM DD"
        clearable
        required
        {...form.getInputProps("olExpiredDate")}
      />
      <Select
        size="m"
        withinPortal
        searchable
        required
        clearable
        label="OL Status"
        placeholder="Select OL Approval Status"
        nothingFound="No results found"
        data={offerLetterStatusOptions}
        {...form.getInputProps("olStatus")}
      />
      <Select
        size="m"
        withinPortal
        searchable
        clearable
        label="GIRO Regis"
        placeholder="Select GIRO Regis"
        nothingFound="No results found"
        data={yesNoOptions}
        {...form.getInputProps("giroRegis")}
      />
      <Textarea
        label="Notes"
        placeholder="Enter Notes"
        autosize
        size="m"
        minRows={3}
        maxRows={3}
        {...form.getInputProps("notes")}
      />
    </>
  );
};

export default OfferingLetterModal;
